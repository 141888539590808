import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function TermsAndConditions({
  product,
  userId,
  stripeId,
  applicationId,
}) {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  useEffect(() => {
    console.log(product);
    console.log("t&c stripe id: " + stripeId);
  }, [product, stripeId]);

  const handleScroll = (e) => {
    const element = e.target;
    // Calculate the 90% scroll position
    const scrollPosition = element.scrollTop + element.clientHeight;
    const ninetyPercentScrollHeight = element.scrollHeight * 0.9;
  
    if (scrollPosition >= ninetyPercentScrollHeight) {
      setIsScrolledToBottom(true);
    }
  };
  

  const termsText = `
  1. Definitions 
  In this Agreement, the following words have the following meaning: 
  ‘Agreement’ means this Subscription Agreement and the Subscription Document. 
  ‘Accessory' and ‘Accessories’ means any equipment specified on the Subscription Document. 
  ‘Commencement Date’ means the date on the Subscription Document. 
  ‘Market Value’ means the agreed upon value of the electronic device(s) noted on the Subscription Document. 
  “Rent” means the Subscription price noted on the Subscription document. 
  “Nominated Account” means the account: 
  AtlasTech Solutions Pty Ltd 
  BSB: 082-926 
  Account: 36-642-2355 
  National Australia Bank 
  ‘Subscription Device(s)': means the Subscription item(s) described in the Subscription Document, including all Accessories. 
  ‘Subscription Document’ means the front page of this Agreement titled ‘Lease Agreement’. 
  ‘Subscription Period’ means the period selected on the Subscription Document, commencing from the Commencement Date where; 
  ‘Daily’ means for one calendar day, ‘Weekly’ means for seven calendar days; ‘Fortnightly’ means for fourteen calendar days
  and ‘Monthly’ means for a period until the same date of the following calendar month. 
  cUs’: ’FlexiPhone’, trading as ‘ATLASTECH SOLUTIONS PTY LTD’ (ABN 38 668 691 441), also referred to as 
  'We,' 'Us,' and 'Our.' 
  'You' and 'Your' means the client noted on the Subscription Document and if two or more clients, means each client jointly and 
  severally. 
  2. Services 
  2.1 In consideration of payment of Rent by You, We agree to lease the Subscription Device to You on the terms of this Agreement. 
  3. Subscription Period 
  3.1 This Agreement is for the Subscription Period, unless extended in accordance clause 3.2 or terminated in accordance with the 
  terms of this Agreement. 
  3.2 This Agreement may be extended for an additional Subscription Period by agreement of all parties. 
  3.3 Your payment of the Rent will constitute an agreement to extend this agreement. 
  4. Rent 
  4.1 You must pay Us the Rent before commencement of this agreement. 
  4.2 You may elect to pay Rent manually to the Nominated Account or by direct debit. 
  4.3 Should you elect to pay Rent via direct debit, You must sign and return the direct debit form We provide to You. 
  4.4 If the Subscription Period is extended in accordance with clause 3.2 above, Rent for that period is due before the first day of the 
  extended Subscription Period. 
  4.5 In the event You are in breach of clause 4.4 by failing to pay Rent when it becomes due and payable, We will issue You a 
  notice demanding You remedy the breach within one day. 
  4.6 Following notice in accordance with clause 4.5, if You remain in breach, We may immediately terminate this Agreement 
  by notice in writing to You. 
  4.7 If We terminate this Agreement in accordance with clause 4.6, clause 8 will apply. 
  5. Condition and Use 
  5.1 You may take possession of the Subscription Device(s) from Us on the Commencement Date. 
  5.2 You agree that the Subscription Device(s) were provided to You in a clean and good operating condition. 
  5.3 During the Subscription Period: 5.3.1 You must not use the Subscription Device(s) for illegal or immoral purposes; 
  5.3.2 You must not allow any third party to use the Subscription Device(s) without Our prior written consent; 
  5.3.3 You must not use the Subscription Device(s) outside of Australia; 
  5.3.4 You must not cause, or permit, the modification, disassembly, decompilation or interference of the software or hardware 
  of the Subscription Device(s); 
  5.3.5 We may request to inspect the Subscription Device(s) at which time You must immediately produce the Subscription Device(s) to 
  Us. 
  5.4 During the Subscription Period: 5.4.1 You are solely responsible for protecting the integrity and security of Your data stored on 
  the Subscription Device(s); 
  5.4.2 You are solely responsible to regularly backup and save the data stored on the Subscription Device(s). We are not liable for 
  any lost data on any Subscription Device(s). 
  6. Damage and Theft 
  6.1 You are solely responsible for the Subscription Device(s) during the Subscription Period. 
  6.2 You must protect and keep safe the Subscription Devices from theft and damage, including by a third party. 
  6.3 If the Subscription Device(s) are found to be in the possession of a third party, they will immediately be considered stolen. 
  6.4 If the Subscription Device(s) are stolen or damaged in any way: 6.4.1 You must notify Us in writing within 24 hours; 
  6.4.2 If requested by Us, You must provide evidence of the damage or theft, including photographic evidence and/or a police 
  report; 
  6.4.3 You agree that You immediately become liable to pay as a debt due and payable: 
  a. the Market Value of the Subscription Device(s); or 
  b. the costs incurred or expected to be incurred by us to repair the Subscription Device(s); and 
  c. and other losses We incur arising from the theft or damage of the Subscription Device(s). 
  7. Return of the Subscription Device(s) 
  7.1 At the end of the Subscription Period, You must return the Subscription Device(s) to us within 24 hours. 
  7.2 If You breach clause 7.1 by failing to return the Subscription Device(s) to Us, We may terminate this Agreement by notice to 
  You, in which case clause 8 applies. We may also report Your unlawful possession to the police. 
  7.3 You may return the Subscription Device(s) earlier than the Subscription Period, in which case, any discounts We provided will be 
  forfeited and paid back to Us as a debt immediately due and payable. 
  7.4 The Subscription Device must be returned to Us in a clean and good operating condition. 
  7.5 Upon the return of the Subscription Device(s), You must: 7.5.1 permanently delete all personal information from the Subscription 
  Device(s) 
  7.5.2 remove all accounts from the Subscription Device(s); and 
  7.5.3 restore the Subscription Device(s) to factory settings. 
  7.6 In the event You breach clause 7.4 or 7.5, You agree that You immediately become liable to pay as a debt due and 
  payable: 7.6.1 the Market Value; or 
  7.6.2 the costs incurred or expected to be incurred by us to repair the Subscription Device(s); and 
  7.6.3 and other losses we may incur. 
  7.7 You agree that upon return of the Subscription Device(s) to release Us and forever hold Us harmless in relation to any 
  intellectual property or data remaining on the Subscription Device(s). 
  8. Termination 
  8.1 Either party may terminate this Agreement at any time. 
  8.2 We may immediately terminate this Agreement if You have materially breached any term. 
  8.3 In the event We terminate this Agreement: 8.3.1 You must immediately return the Subscription Device(s) to Us; 
  8.3.2 For each day that you fail, neglect or refuse to return the Subscription Device(s), You agree that You are liable to pay us the 
  daily Subscription rate for each day until you return the Subscription Device(s); 
  8.3.3 If the Subscription Device(s) are not returned within 14 days of this Agreement being terminated, the Market Value of the 
  Subscription Device(s) and all associated costs and expenses become a debt immediately due and payable to Us; and 
  8.3.4 Any outstanding Rent becomes a debt immediately due and payable to Us. 
  9. Court Proceedings 
  9.1 We may commence proceedings against You without notice in relation to any claims, causes of action, debts, dues, claims,
  damages, losses, costs and expenses of any description We may have against You under this Agreement. 
  9.2 If proceedings are commenced, You agree you are liable for all associated costs, including solicitor costs (on a full 
  indemnity basis). 
  10. General 
  10.1 Nothing in this Agreement creates any relationship of agency or joint venture. 
  10.2 We may only waive a right if it is done so in writing and signed by Us. 
  10.3 You agree to indemnify Us against any and all claims, demands, actions, liabilities, expenses, and costs (including, but 
  not limited to, legal costs on an indemnity basis) incurred by Us as a result of any failure, for any reason, to fulfill Your 
  obligations under this Agreement. 
  10.4 You acknowledge that Your interest in the Subscription Device(s) is solely as a bailee. You agree not to part with possession, 
  dispose of, encumber, assign any rights or interests in the Subscription Device(s), or create any liens on the Subscription Device(s) for 
  repairs. 
  10.5 You have no legal interest or rights in the Subscription Device(s). 
  10.6 To the extent permitted by law, Our liability is limited to repair, replacement, or re-supply of the Subscription Device(s) for the 
  Term. 
  10.7 We may assign any rights or obligations under this Agreement without prior notice to You. 
  10.8 You may not assign any rights or obligations under this Agreement without Our prior written consent. 
  10.9 This Agreement shall be interpreted in accordance with and exclusively governed by the laws of the Australian Capital 
  Territory.
  `;

  return (
    <div className="mx-auto">
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Terms & Conditions
          </h2>
          Please scroll to bottom of Terms & Conditions to continue
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-6 sm:col-start-1">
              <div className="overflow-y-scroll h-96" onScroll={handleScroll}>
                <pre>{termsText}</pre>
              </div>
            </div>
          </div>
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <Link
              to={"/payment"}
              state={{ product, userId, stripeId, applicationId }}
            >
              <button
                type="button"
                className={`rounded-md px-10 py-2 text-sm font-semibold text-white shadow-sm ${
                  isScrolledToBottom
                    ? "bg-primary-blue hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-blue"
                    : "bg-gray-400 cursor-not-allowed"
                }`}
                disabled={!isScrolledToBottom}
              >
                Next
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
