import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import ErrorPage from "./pages/ErrorPage";
import ContactUsPage from "./pages/ContactUs/ContactUsPage";
import Layout from "./pages/Layout";
import ProductPage from "./pages/ProductPage/ProductPage";
import ProductListPage from "./pages/ProductListPage/ProductListPage";
import AboutUsPage from "./pages/AboutUsPage/AboutUsPage";
import ThankYouPage from "./pages/ThankYouPage/ThankYouPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import ApplyPage from "./pages/ApplyProcess/ApplyPage";
import Wrapper from "./Wrapper";
import PaymentPage from "./pages/ApplyProcess/PaymentPage";
import TermsAndConditionsPage from "./pages/ApplyProcess/TermsAndConditionsPage";
import IdentificationInformationPage from "./pages/ApplyProcess/IdentificationInformationPage";
import CheckoutPage from "./pages/CheckoutPage";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PrivacyPolicyPage from "./pages/Legal/PrivacyPolicyPage";
import { RefundPolicyPage } from "./pages/Legal/RefundPolicyPage";
import { TermsOfUsePage } from "./pages/Legal/TermsOfUsePage";
import { SalesAndReturnPage } from "./pages/Legal/SalesandReturnsPage";
import posthog from "posthog-js";
import withPageTracking from "./functions/WithTracking";
import WithTracking from "./functions/WithTracking";
import { QueryClient, QueryClientProvider } from "react-query";

posthog.init("phc_NqNh8MsDhDGuF0mFyYxVQzcrFBd09KqdOKNmlAhcgGs", {
  api_host: "https://flexiphone.com.au/ingest",
  ui_host: "https://app.posthog.com", // or 'https://eu.posthog.com' if your PostHog is hosted in Europe
});

const stripeKey = process.env.REACT_APP_STRIPE_API

const stripePromise = loadStripe(
  stripeKey
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true, // This enables suspense mode
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Elements stripe={stripePromise}>
          <Wrapper>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route
                  index
                  element={
                    <WithTracking>
                      <HomePage />
                    </WithTracking>
                  }
                />
                <Route
                  path="contactUs"
                  element={
                    <WithTracking>
                      <ContactUsPage />
                    </WithTracking>
                  }
                />
                <Route
                  path="aboutUs"
                  element={
                    <WithTracking>
                      <AboutUsPage />
                    </WithTracking>
                  }
                />
                <Route
                  path=":productTitle/:productId"
                  element={
                    <WithTracking>
                      <ProductPage />
                    </WithTracking>
                  }
                />
                <Route
                  path="*"
                  element={
                    <WithTracking>
                      <ErrorPage />
                    </WithTracking>
                  }
                />
                <Route
                  path="products"
                  element={
                    <WithTracking>
                      <ProductListPage />
                    </WithTracking>
                  }
                />
                <Route
                  path="thankYou"
                  element={
                    <WithTracking>
                      <ThankYouPage />
                    </WithTracking>
                  }
                />
                <Route
                  path="login"
                  element={
                    <WithTracking>
                      <LoginPage />
                    </WithTracking>
                  }
                />
                <Route
                  path="apply"
                  element={
                    <WithTracking>
                      <ApplyPage />
                    </WithTracking>
                  }
                />
                <Route
                  path="payment"
                  element={
                    <WithTracking>
                      <PaymentPage />
                    </WithTracking>
                  }
                />
                <Route
                  path="termsandconditions"
                  element={
                    <WithTracking>
                      <TermsAndConditionsPage />
                    </WithTracking>
                  }
                />
                <Route
                  path="identificationInformation"
                  element={
                    <WithTracking>
                      <IdentificationInformationPage />
                    </WithTracking>
                  }
                />
                <Route
                  path="checkout-test"
                  element={
                    <WithTracking>
                      <CheckoutPage />
                    </WithTracking>
                  }
                />
                <Route
                  path="privacy-policy"
                  element={
                    <WithTracking>
                      <PrivacyPolicyPage />
                    </WithTracking>
                  }
                />
                <Route
                  path="refund-policy"
                  element={
                    <WithTracking>
                      <RefundPolicyPage />
                    </WithTracking>
                  }
                />
                <Route
                  path="terms-of-use-policy"
                  element={
                    <WithTracking>
                      <TermsOfUsePage />
                    </WithTracking>
                  }
                />
                <Route
                  path="sales-and-return-policy"
                  element={
                    <WithTracking>
                      <SalesAndReturnPage />
                    </WithTracking>
                  }
                />
              </Route>
            </Routes>
          </Wrapper>
        </Elements>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
